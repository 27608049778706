import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Jsme } from "jsme-react";
import {
  Col,
  Row,
  Container,
  Form,
  Button,
  Table,
  PageItem,
  Modal,
  Spinner,
  Collapse,
  Accordion,
  ButtonGroup,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import axios from "axios";
import initRDKit from "./initrdkit";
import { useSearchParams } from "react-router-dom";
import ReactGA from "react-ga4";

ReactGA.initialize("YOUR_TRACKING_ID");

function App() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [rdKit, setRdKit] = React.useState(null);
  const [smiles, setSmiles] = React.useState(
    searchParams.get("smiles") ? searchParams.get("smiles") : ""
  );
  const [smilestxt, setSmilestxt] = React.useState(
    searchParams.get("smiles") ? searchParams.get("smiles") : ""
  );
  const [libid, setLibid] = React.useState("bb-50");
  const [tc, setTc] = React.useState(0.35);
  const [id, setID] = React.useState("");
  const [task, setTask] = React.useState("");
  const [results, setResults] = React.useState([]);
  const [analogs_length, setAnalogsLength] = React.useState(0);
  const [modalElements, setModalElements] = React.useState([]);
  const [bbs, setBb] = React.useState([]);
  const [pairs, setPairs] = React.useState([]);
  const [currentbb, setCurrentbb] = React.useState("");
  const [show, setShow] = React.useState(false);
  const [spin, setSpin] = React.useState(false);
  const [analogsSpinner, setAnalogsSpinner] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setAnalogsSpinner(true);
    setShow(true);
    setModalElements(<Spinner animation="border" variant="info" />);
    axios.get("/getanalogs/" + id).then((response) => {
      setAnalogsSpinner(false);
      setModalElements(
        response.data.analogs_list.map((item, index) => (
          <tr key={index}>
            <td>
              <img
                height="200"
                width="200"
                src={"https://sw.docking.org/depict/svg?smi=" + item.smiles}
              />
            </td>
            <td>{item.smiles}</td>
            <td>
              <a
                href={
                  "https://cartblanche22.docking.org/substance/" + item.orig
                }
                target="_blank"
              >
                {item.orig}
              </a>
            </td>
          </tr>
        ))
      );
    });
  };

  function downloadTXT() {
    let smilesList = ["Building Block" + "\t" + "Reaction" + "\n"];
    const element = document.createElement("a");
    bbs.map((item) =>
      smilesList.push(item.smiles + "\t" + item.Reaction_name + "\n")
    );
    const file = new Blob(smilesList, { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "BBsmiles.txt";
    document.body.appendChild(element);
    element.click();
  }

  React.useEffect(() => {
    initRDKit().then((rdKit) => {
      setRdKit(rdKit);
    });
  }, []);

  function downloadTXT2() {
    let analogsList = ["Analog" + "\t" + "Purchasability" + "\n"];
    const element = document.createElement("a");
    for (let i = 0; i < results.length; i++) {
      var item = results[i];
      if (item.building_block.smiles === currentbb) {
        item.analogs_list.map((analog, index) =>
          analogsList.push(analog.smiles + "\t" + analog.orig + "\n")
        );
      }
    }

    const file = new Blob(analogsList, { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "analogsmiles.txt";
    document.body.appendChild(element);
    element.click();
  }

  function showspinner() {
    if (spin === true) {
      return <Spinner animation="grow" variant="info" />;
    } else {
      return;
    }
  }
  function showspinner2() {
    if (spin === true) {
      return <Spinner animation="border" variant="info" />;
    } else {
      return;
    }
  }

  function submitTask() {
    // const data = { smiles: smilestxt, tc: tc, id: id, libid: libid };
    var bodyFormData = new FormData();

    bodyFormData.append("smiles", smilestxt);

    bodyFormData.append("tc", tc);
    bodyFormData.append("id", id);
    bodyFormData.append("libid", libid);

    axios({
      url: "/submittask",
      method: "post",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((response) => {
      setBb([]);
      setPairs([]);
      setTask(response.data.task);
      setSpin(true);
      const progressRequest = setInterval(getProgress, 500);
      function getProgress() {
        axios.get("/progress?task=" + response.data.task).then((result) => {
          if (result.data.ready === true) {
            setResults(result.data.arthor_results);
            // var list = []
            // result.data.arthor_results.map((item) => (
            //   item.analogs_list.map((analog, index) => (
            //     list.push(analog.smiles)
            //   ))
            // ))
            setSpin(false);
            clearInterval(progressRequest);
            setBb([]);
            setBb(result.data.results);

            let pairs = {};
            for (var i = 0; i < result.data.results.length; i++) {
              var item = result.data.results[i];
              if (item.pair_id in pairs) {
                pairs[item.pair_id].push(item);
              } else {
                pairs[item.pair_id] = [item];
              }
            }
            setPairs([]);
            setPairs(pairs);
          }
        });
      }
    });
  }

  function submitTest() {
    setTc(0.25);
    setSmiles("Cc3ccc(NC(=O)c2ccc(CN1CCN(C)CC1)cc2)cc3Nc5nccc(c4cccnc4)n5");
  }

  // React.useEffect(() => {
  //   showResults();
  // }, [currentbb]);

  function showResults() {
    const elements = [];
    for (let i = 0; i < results.length; i++) {
      var item = results[i];
      if (item.building_block.smiles === currentbb) {
        item.analogs_list.map((analog, index) =>
          elements.push(
            <tr key={index}>
              <td>
                <img
                  height="200"
                  width="200"
                  src={
                    "https://sw.docking.org/depict/svg?smi=" +
                    analog.encoded_smiles
                  }
                />
              </td>
              <td>
                {analog.smiles}
                <Button
                  variant="info"
                  onClick={() => {
                    navigator.clipboard.writeText(analog.smiles);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 384 512"
                  >
                    <path d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM112 192H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                  </svg>
                </Button>
              </td>
              <td>
                <a
                  href={
                    "https://cartblanche22.docking.org/substance/" + analog.orig
                  }
                  target="_blank"
                >
                  {analog.orig}
                </a>
              </td>
            </tr>
          )
        );
      }
    }

    if (elements.length === 0) {
      setModalElements(
        <tr>
          <td>No analogs found</td>
        </tr>
      );
    } else {
      setModalElements(elements);
    }
  }

  function showBbs() {
    let bbs = {};
    let reactions = [];

    Object.keys(pairs).map((item) => {
      let name = pairs[item][0]["Reaction_name"];
      let id = pairs[item][0]["Reaction_id"];
      let pair_id = item;

      if (!bbs["pair_id"]) {
        let building_blocks = [];
        pairs[item].map((bb) => {
          building_blocks.push(bb);
        });
        reactions.push({
          name: name,
          id: id,
          building_blocks: building_blocks,
        });
        bbs[pair_id] = "Hello";
      }
    });

    return reactions.map((item, index) => (
      <>
        <tr
          key={index}
          data-toggle="collapse"
          data-target={".multi-collapse" + index}
          aria-controls={"multiCollapseExample" + index}
        >
          <td>
            {item.name}
            <br></br>
            {item.id}
          </td>
        </tr>

        {item.building_blocks.map((bb) => (
          <tr>
            <td></td>

            <td>
              <img
                height="240"
                width="240"
                src={
                  "https://sw.docking.org/depict/svg?smi=" + bb.encoded_smiles
                }
              />
            </td>
            <td>{bb.smiles}</td>

            <td>
              <Button
                variant="outline-info"
                onClick={() => handleShow(bb.arthor_task)}
              >
                Analogs
              </Button>
            </td>
          </tr>
        ))}
      </>
    ));
  }
  function writesmiles(smiles, fromJSME = false, fromText = false) {
    if (fromJSME) {
      setSmilestxt(smiles);
    } else if (fromText) {
      setSmilestxt(smiles);
      setSmiles(smiles);
    }
  }

  return (
    <Container fluid className="pb-5">
      <Row>
        <Col className="pt-4" lg={4} md={6} sm={12}>
          <Form onSubmit={submitTask}>
            <Form.Group>
              <Jsme
                src="/jsme/jsme.nocache.js"
                height="20rem"
                width="auto"
                options={"noautoez,newlook,nocanonize,multipart,zoom"}
                smiles={
                  rdKit && rdKit.get_mol(smiles)
                    ? rdKit.get_mol(smiles).get_smiles()
                    : smiles
                }
                onChange={(smiles) => writesmiles(smiles, true, false)}
              />
            </Form.Group>

            <Form.Group minwidth="400px" maxwidth="400px">
              <Form.Label>
                <b>SMILES</b>{" "}
              </Form.Label>
              <Form.Control
                type="text"
                value={smilestxt}
                onChange={(e) => writesmiles(e.target.value, false, true)}
              />
            </Form.Group>
            <Row>
              <Col lg={6} md={6} sm={6}>
                <Form.Group>
                  <Form.Label>
                    <b>TC</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    defaultValue="0.35"
                    min="0"
                    max="1"
                    step="0.01"
                    value={tc}
                    onChange={(e) => setTc(e.target.value)}
                  />
                  <Form.Range
                    type="range"
                    defaultValue="0.35"
                    value={tc}
                    min="0"
                    max="1"
                    step="0.01"
                    onChange={(e) => setTc(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col lg={6} md={6} sm={6}>
                <Form.Group>
                  <Form.Label>
                    <b>RxnID</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={id}
                    onChange={(e) => setID(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <br></br>
          <Button variant="info" onClick={submitTask}>
            Submit
          </Button>{" "}
          <ButtonGroup>
            <Button variant="secondary" disabled>
              Library
            </Button>
            <DropdownButton
              as={ButtonGroup}
              title={libid}
              id="bg-nested-dropdown"
            >
              <Dropdown.Item onClick={() => setLibid("bb-50")}>
                <b>BB-50</b>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setLibid("bb-40")}>
                <b>BB-40</b>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setLibid("bb-30")}>
                <b>BB-30</b>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setLibid("bb-20")}>
                <b>BB-20</b>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setLibid("bb-10")}>
                <b>BB-10</b>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setLibid("real-space")}>
                <b>REAL</b>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setLibid("chemspaceb")}>
                <b>ChemspaceBB</b>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setLibid("sialbb")}>
                <b>SiAlBB</b>
              </Dropdown.Item>
            </DropdownButton>
          </ButtonGroup>
          &nbsp;
          <Button variant="outline-info" onClick={submitTest}>
            Test Gleevec
          </Button>
          {/* <Button variant="outline-info" id="download" onClick={downloadTXT}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              margin="3"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-cloud-download-fill"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.5a.5.5 0 0 1 1 0V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.354 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V11h-1v3.293l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"
              />
            </svg>
          </Button> */}
        </Col>

        <Col lg={8} md={6} sm={12} className="pt-4">
          <div className="overflow-auto" style={{ maxHeight: "650px" }}>
            <Table table-light striped bordered hover size="sm">
              <thead>
                <th>Rxn/Rxn ID</th>
                <th>BBs:{bbs.length}</th>
                <th>BB SMILES</th>
                <th>Analogs</th>
              </thead>
              <tbody>
                {showspinner2()}
                {showspinner()}
                {showspinner2()}
                {showBbs()}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>

      <Modal show={show} size="lg" onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {currentbb} Building Block Analogs: {modalElements.length}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="overflow-auto" style={{ maxHeight: "500px" }}>
            <Table striped bordered hover>
              <thead>
                <th>Molecule</th>
                <th>SMILES</th>
                <th>Purchasability</th>
              </thead>
              <tbody>{modalElements}</tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={downloadTXT2}>
            Download
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default App;
